<template>
  <v-container id="conductores" fluid tag="section">
    <v-card>
      <v-card-text>
        <v-card outlined>
          <v-card-subtitle>
            <v-btn
              :color="$cv('principal')"
              @click="showPanel = !showPanel"
              text
              :title="
                !showPanel
                  ? 'Abrir panel de Consulta de ' + title
                  : 'Cerrar panel de Consulta de ' + title
              "
              class="text-left"
            >
              Consulta de {{ title }} - {{ tipoF01 }}
            </v-btn>
          </v-card-subtitle>
          <v-expand-transition>
            <v-card-text v-show="showPanel">
              <v-form ref="formBusqueda" v-model="valid" lazy-validation>
                <!--
								<v-row dense>
									<v-col cols="12" md="6" sm="12">

									</v-col>
									<v-col cols="12" md="6" sm="12">
										<v-menu
											ref="menuFecha"
											v-model="menuFecha"
											:close-on-content-click="false"
											:return-value.sync="date"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="dateRangeText"
													label="Fecha Pedido"
													readonly
													v-bind="attrs"
													v-on="on"
												></v-text-field>
											</template>
											<v-date-picker v-model="dates" range no-title scrollable>
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="menuFecha = false">
													Cancelar
												</v-btn>
												<v-btn
													text
													color="primary"
													@click="$refs.menuFecha.save(date)"
												>
													OK
												</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
								</v-row>
                -->
                <!--
                <v-row>
                  <v-col cols="12" md="3" sm="12">
                    <v-text-field
                      v-model="formSearch.patente"
                      label="Patente"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-text-field
                      v-model="formSearch.anio"
                      label="Año"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-text-field
                      v-model="formSearch.chasis"
                      label="Chasis"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="marcas"
                      item-value="id"
                      item-text="nombre"
                      label="Marca"
                      v-model="formSearch.marca_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              -->
                <!--
                <v-row>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="tipos"
                      item-value="id"
                      item-text="nombre"
                      label="Tipo de Vehiculo"
                      v-model="formSearch.tipo_vehiculo_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="deptos"
                      item-value="id"
                      item-text="nombre"
                      label="Departamento"
                      v-model="formSearch.depto_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="estados"
                      item-value="id"
                      item-text="nombre"
                      label="Estados"
                      v-model="formSearch.estado_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="combustibles"
                      item-value="id"
                      item-text="nombre"
                      label="Combustibles"
                      v-model="formSearch.combustible_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                -->
                <v-row dense>
                  <v-col cols="12" md="12" sm="12" justify="end" align="right">
                    <v-btn
                      :color="$cv('principal')"
                      small
                      @click="
                        page = 1;
                        initialize();
                      "
                    >
                      Buscar
                    </v-btn>
                    <v-btn :color="$cv('btnEditar')" small @click="reset">
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col></v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-expand-transition>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :loading="loading"
          class="elevation-1"
          :options.sync="pagination"
          :server-items-length="total"
          :footer-props="footerProps"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title
                >Lista de {{ title }} - {{ tipoF01 }}</v-toolbar-title
              >
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <template v-if="visible">
                <v-btn
                  :color="$cv('principal')"
                  dark
                  class="mx-2"
                  fab
                  @click="$router.push('/tramites/f01_autos/form')"
                  title="Nuevo Trámite"
                  ><v-icon blue>mdi-plus</v-icon></v-btn
                >
              </template>

              <!--
                  <v-dialog v-model="dialogPdf" persistent fullscreen max-width="100%">

                  <template v-if="visible" v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="$cv('principal')"
                    dark
                    class="mx-2"
                    fab
                    v-bind="attrs"
                    @click="$router.push('/tramites/f01_autos/form')"
                    title="Nuevo Trámite"
                    ><v-icon blue>mdi-plus</v-icon></v-btn
                  >
                </template>
                <v-card>

                  <f01-wizard-component
                    :key="componentKey"
                    :formDataWizard="editedItem"
                    :tipoF01="tipoF01"
                    @send-message="close()"
                    ref="f01Wizard"
                  ></f01-wizard-component>

                </v-card>

						</v-dialog>-->
            </v-toolbar>
            <v-spacer></v-spacer>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-row class="">
              <v-col cols="12" md="12" sm="12" style="padding: 6px 0px 0px 0px">
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="transparent elevation-0"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                      min-width="30px"
                    >
                      <v-icon
                        large
                        color="blue-grey lighten-3"
                        style="font-size: 30px"
                      >
                        mdi-dialpad
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon class="mr-0"
                        ><v-icon
                          small
                          class="mr-2"
                          color="blue"
                          title="Editar Formulario"
                        >
                          mdi-pencil
                        </v-icon> </v-list-item-icon
                      ><v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="verPdf(item)">
                      <v-list-item-icon class="mr-0"
                        ><v-icon
                          small
                          class="mr-2"
                          color="blue"
                          title="PDF Formulario"
                        >
                          mdi-file-pdf-box
                        </v-icon> </v-list-item-icon
                      ><v-list-item-content>
                        <v-list-item-title>Formularios</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="getAllF01(item)">
                      <v-list-item-icon class="mr-0"
                        ><v-icon
                          small
                          class="mr-2"
                          color="orange"
                          title="Descargar Formularios 01"
                        >
                          mdi-folder-zip
                        </v-icon> </v-list-item-icon
                      ><v-list-item-content>
                        <v-list-item-title
                          >Descargar Formularios 01</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <!--
                    <v-list-item @click="$deleteItem(item.id, item.patente)">
                      <v-list-item-icon class="mr-0">
                        <v-icon
                          small
                          class="mr-2"
                          color="orange"
                          title="Eliminar"
                        >
                          mdi-delete
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  -->
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-row>
        <v-col cols="12" md="12" sm="12" class="text-right">
          <v-btn :color="$cv('principal')">
            <download-excel
              :fields="json_fields"
              :fetch="fetchData"
              :type="dataExportType"
              :name="
                'export-' + title + '-' + this.getDate() + '.' + dataExportType
              "
            >
              Exportar Excel
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>
      <!--
      <v-dialog v-model="dialogDetails" fullscreen scrollable persistent>
        <vehiculo-details-component
          :key="componentKeyDetails"
          v-bind:dataVehiculo="editedItem"
          @send-message="close(editedItem)"
        ></vehiculo-details-component>
      </v-dialog>
    -->
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Formularios 01";
}

export default {
  components: {
    //FormPdf: () => import("./components/FormPdf"),
  },
  props: {
    tipoF01: { type: String, default: null },
  },
  data: (vm) => ({
    componentKey: 0,
    componentKeyDetails: 0,
    tipos: [],
    marcas: [],

    estados: [],

    dates: [],
    formSearch: {},
    pagination: {},
    total: 0,
    loading: true,
    footerProps: { "items-per-page-options": [5, 10, 15, 30] },
    menuFecha: false,
    showPanel: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    valid: true,
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    routeIndex: "getFormularios",
    /*route: "formularios",*/
    route: "showFormulario",
    urlExport: "formularios_export",
    dataExportType: "xls",
    json_fields: {},
    menu: false,
    modal: false,
    dialogPdf: false,
    dialogDetails: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,

    search: "",

    users: false,
    headers: [
      /*{ text: "Numero", filterable: true, value: "numero" },*/
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
        class: "acciones-width",
      },
      { text: "Numero de Tramite", value: "id", sortable: true },
      { text: "Fecha", value: "created_at", align: "start", sortable: true },
      { text: "Tipo de Tramite", value: "tipo_tramite" },
      { text: "Certificado", value: "numero_certificado" },
      { text: "Tipo de Certificado", value: "tipo_certificado" },
      { text: "Chasis", value: "chasis" },
      { text: "Titulares", value: "titulares" },
      { text: "Estado", value: "estado" },
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: null,
    defaultItem: {},
  }),

  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (date) {
        return date;
      },
    },
  },

  watch: {
    dialog(val) {
      if (val == true) {
        this.componentKey++;
        this.componentKeyDetails++;
      }

      val || this.close();
    },
    pagination: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    getDate() {
      return Math.floor(Date.now() / 1000);
    },
    async fetchData() {
      let query = this.formSearch;
      let formData = new FormData();
      formData = this.getQryParams(query);
      let queryParams = new URLSearchParams(formData).toString();
      let url = this.urlExport;
      const response = await this.$axiosApi.getByQuery(url, queryParams);
      return response.data.data;
    },
    editItem(item) {
      let id = item.id;

      this.$router.push({
        name: "f01_autos_edit",
        params: {
          id: id,
          tipo_certificado: item.tipo_certificado,
          numero_certificado: item.numero_certificado,
          chasis: item.chasis,
        },
      });
    },

    reset() {
      this.dates = [];
      this.$refs.formBusqueda.reset();
      this.initialize();
    },
    getQryParams(dataSearch) {
      let formData = new FormData();
      if (this.tipoF01) {
        formData.append("tipo_f01", this.tipoF01);
      }
      if (dataSearch.tipo_vehiculo_id) {
        formData.append("tipo_vehiculo_id", dataSearch.tipo_vehiculo_id);
      }
      if (dataSearch.combustible_id) {
        formData.append("combustible_id", dataSearch.combustible_id);
      }
      if (dataSearch.marca_id) {
        formData.append("marca_id", dataSearch.marca_id);
      }
      if (dataSearch.zona_id) {
        formData.append("zona_id", dataSearch.zona_id);
      }
      if (dataSearch.depto_id) {
        formData.append("depto_id", dataSearch.depto_id);
      }
      if (dataSearch.estado_id) {
        formData.append("estado_id", dataSearch.estado_id);
      }
      if (dataSearch.version_id) {
        formData.append("version_id", dataSearch.version_id);
      }
      if (dataSearch.numero) {
        formData.append("numero", dataSearch.numero);
      }
      if (dataSearch.anio) {
        formData.append("anio", dataSearch.anio);
      }
      if (dataSearch.chasis) {
        formData.append("chasis", dataSearch.chasis);
      }
      if (dataSearch.patente) {
        formData.append("patente", dataSearch.patente);
      }
      if (this.dates.length > 0) {
        formData.append("created_at_start", this.dates[0]);
        formData.append("created_at_end", this.dates[1]);
      }

      return formData;
    },
    initialize() {
      this.loading = false;
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let url = this.routeIndex;

      if (this.formSearch && !sortBy) {
        let formData = new FormData();
        formData = this.getQryParams(this.formSearch);
        let query = new URLSearchParams(formData).toString();
        query =
          query +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage;

        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));

        return;
      }

      if (this.formSearch && sortBy) {
        let formData = new FormData();
        formData = this.getQryParams(this.formSearch);

        let query = new URLSearchParams(formData).toString();
        let direction = "desc";
        //sortDesc[0] == true || sortDesc[0] == undefined ? "desc" : "asc";
        query =
          query +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage +
          "&sortBy=" +
          this.pagination.sortBy +
          "&direction=" +
          direction;

        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));

        return;
      }

      if (sortBy && !this.formSearch) {
        let direction =
          sortDesc[0] == true || sortDesc[0] == undefined ? "desc" : "asc";
        let query =
          "?direction=" +
          direction +
          "&sortBy=" +
          this.pagination.sortBy +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage;

        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));
      }

      if (!this.formSearch && !sortBy) {
        //console.log('no hay parametros');
        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            //console.log(r.data);
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));
      }
    },

    close() {
      this.dialog = false;
      this.dialogDetails = false;
      this.dialogPdf = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.initialize();
    },

    verPdf(item) {
      //this.dialogPdf = true;
      let id = item.id;
      this.$router.push({
        name: "f01_autos_pdf",
        params: {
          id: id,
          tipo_certificado: item.tipo_certificado,
          numero_certificado: item.numero_certificado,
          chasis: item.chasis,
        },
      });
    },

    async getAllF01(item) {
      Swal.alertGetInfo(
        "Obteniendo Formularios 01 del certificado " +
          item.numero_certificado +
          "<br><b></b>"
      );
      let id = item.id;
      let route = "formulario01Zip";
      const response = await this.$axiosApi.zipLoad(route, id);
      Swal.close();
      if (typeof response != "undefined") {
        if (response.status !== 200) {
          Swal.alertError(
            "Atención",
            "Se ha producido un error al generar los Formularios F01. Verifique la información"
          );
          return;
        }
      }

      return response;
    },
  },
  mounted() {
    console.log("Componente " + title() + " creado");
    this.$hideMenu();
  },
};
</script>
